
import { login, sendCaptcha, loginUser } from '@/api/index.js';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { isWeiXin } from '@/utils/bank';
import Toastrs from '@/utils/Toastrs';
@Component
export default class MoPassWord extends Vue {
  public form: any = {
    mobile: '',
    passwd: '',
    purpose: 'login',
  };

  public async login() {
    const reg = /^1[3456789]\d{9}$/;
    if (!reg.test(this.form.mobile)) {
      Toastrs.error(`请输入正确的手机号码`);
      return;
    }

    if (!this.form.passwd) {
      Toastrs.error(`请输入密码`);
      return;
    }
    const res: any = await ((this as any).user_type == 2 ?  loginUser:login)({ source_appid: (this as any).appid, channels: 'passwd', ...this.form });
    if (res.code == 200 || res.code ==0) {
      (this as any).loginSuccess(res.data.token);
    }
  }
}
